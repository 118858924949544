document.addEventListener('alpine:init', () => {

    Pristine.addMessages("da", {
        required: "Dette felt er påkrævet",
        email: "Dette felt kræver en gyldig e-mailadresse",
        number: "Dette felt kræver et tal",
        integer: "Dette felt kræver en heltalsværdi",
        url: "Dette felt kræver en gyldig webadresse",
        tel: "Dette felt kræver et gyldigt telefonnummer",
        maxlength: "Dette felts længde skal være < ${1}",
        minlength: "Dette felts længde skal være > ${1}",
        min: "Minimumsværdien for dette felt er ${1}",
        max: "Maksimumsværdien for dette felt er ${1}",
        pattern: "Venligst match den ønskede format",
        equals: "De to felter stemmer ikke overens",
        default: "Indtast venligst en korrekt værdi"
    })
    Pristine.setLocale("da")



    Alpine.data('cloneForm', () => ({
        pristine: null,
        loading: false,

        init() {
            var form = this.$root.querySelector("form")
            this.pristine = new Pristine(form);
        },

        submit(e) {
            var valid = this.pristine.validate();
            if (!valid) {
                e.preventDefault();
            } else {
                this.loading = true
            }
        }

    }));


    Alpine.data('agicheckForm', () => ({
        pristine: null,
        loading: true,

        init() {
            var form = this.$root.querySelector("form")
            this.pristine = new Pristine(form);
        },

        submit(e) {
            var valid = this.pristine.validate();
            if (!valid) {
                e.preventDefault();
            } else {
                this.loading = true
            }
        }

    }));


    Alpine.data('imagePicker', () => ({
        preview: null,

        addFile(event) {
            var input = event.target

            if (input.files) {
                var reader = new FileReader()
                reader.onload = (e) => {
                    this.preview = e.target.result
                }
                reader.readAsDataURL(input.files[0])
            }
        },

        clearFile() {
            this.preview = null
            this.$refs.file.value = null
        }

    }));


});
