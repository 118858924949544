import "./globalSearch.js";

document.addEventListener("alpine:init", () => {
    Alpine.data("global", () => ({
        mobileNavOpen: false,
        sticky: false,
        sales: false,
        pdfViewModal: false,
        finance: false,
        customView: {
            carTileFinance: false,
        },
        paymentValue: "", // Add a state for storing the payment value
        initPaymentFetch() {
            // Method to initiate the fetch process
            this.waitForElement("#paymentPerMonth", (content) => {
                this.paymentValue = content; // Update state with payment value
            });
        },
        waitForElement(selector, callback) {
            const interval = 100; // Check every 100 milliseconds
            const maxAttempts = 100; // Increase maximum attempts
            let attempts = 0;

            const intervalId = setInterval(() => {
                const element = document.querySelector(selector);
                if (element && element.textContent.trim() !== "") {
                    clearInterval(intervalId);
                    callback(element.textContent.trim());
                } else if (++attempts >= maxAttempts) {
                    clearInterval(intervalId);
                }
            }, interval);
        },
    }));
});

/* Scroll: Make */
function draggableScroll() {
    return {
        isDragging: false,
        startX: 0,
        scrollLeft: 0,
        startDrag(event) {
            this.isDragging = true;
            this.startX = event.pageX;
            this.scrollLeft = this.$el.querySelector(".flex").scrollLeft;
        },
        stopDrag() {
            this.isDragging = false;
        },
        drag(event) {
            if (!this.isDragging) return;
            event.preventDefault();
            const x = event.pageX;
            const walk = (x - this.startX) * 2; // Adjust the speed factor as necessary
            this.$el.querySelector(".flex").scrollLeft = this.scrollLeft - walk;
        },
    };
}

document.addEventListener("DOMContentLoaded", function () {
    // Define a regex pattern to match the phone number with possible variations
    var phoneNumberPattern =
        /^(tel:)?(\+45|\(\+45\))?\s?8\s?6\s?8\s?0\s?2\s?0\s?8\s?0|^(tel:)?(\+45|\(\+45\))?\s?8\s?6\s?8\s?0\s?2\s?0\s?8\s?0|^(tel:)?(\+45|\(\+45\))?\s?8\s?6\s?8\s?0\s?2\s?0\s?8\s?0|^(tel:)?(\+45|\(\+45\))?\s?8\s?6\s?8\s?0\s?2\s?0\s?8\s?0$/;

    // Get all anchor elements
    var anchors = document.getElementsByTagName("a");

    // Loop through all anchor elements
    for (var i = 0; i < anchors.length; i++) {
        // Check if the href attribute matches the phone number pattern
        if (phoneNumberPattern.test(anchors[i].href)) {
            // Add an event listener to the anchor element
            anchors[i].addEventListener("click", function () {
                // Push the event to the dataLayer
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "call",
                    phoneNumber: "86 999 999",
                });
                s;
            });
        }
    }
});

document.addEventListener("DOMContentLoaded", function () {
    function setupDarkModeToggles(toggleElementClass, storageKey = "dark-mode") {
        const body = document.body;
        const toggleElements = document.querySelectorAll(`.${toggleElementClass}`);

        if (!toggleElements.length) {
            //console.error(`No elements with class ${toggleElementClass} found.`);
            return;
        }

        // Check for saved user preference, if any, on load of the website
        if (localStorage.getItem(storageKey) === "true") {
            body.classList.add("dark");
            toggleElements.forEach((icon) => {
                icon.classList.add("dark");
            });
        }

        // Toggle dark mode on click for each toggle element
        toggleElements.forEach((icon) => {
            icon.addEventListener("click", function () {
                body.classList.toggle("dark");
                toggleElements.forEach((toggleIcon) => {
                    toggleIcon.classList.toggle("dark");
                });

                // Update localStorage based on mode
                if (body.classList.contains("dark")) {
                    localStorage.setItem(storageKey, "true");
                } else {
                    localStorage.setItem(storageKey, "false");
                }
            });
        });
    }

    // Initialize the dark mode toggles
    setupDarkModeToggles("mode-switch");
});
