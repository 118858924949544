// Animated gallery hover effect.
document.addEventListener("DOMContentLoaded", function () {
  let galleries = [];

  function initializeGalleries() {
    // Clear existing galleries
    galleries.forEach((gallery) => gallery.destroy());
    galleries = [];

    // Check if the device is not mobile (lg breakpoint in Tailwind is 1024px)
    const isDesktop = window.matchMedia("(min-width: 1024px)").matches;

    if (isDesktop) {
      const articles = document.querySelectorAll(".carTile");

      articles.forEach((article) => {
        const gallery = article.querySelector(".image-gallery");
        if (!gallery) return;

        const images = gallery.querySelectorAll(".gallery-img");
        const totalImages = parseInt(gallery.dataset.totalImages);

        let isHovering = false;
        let imagesLoaded = false;

        function onMouseEnter() {
          isHovering = true;
          if (!imagesLoaded) {
            loadImages();
          }
        }

        function onMouseLeave() {
          isHovering = false;
          hideAllImages();
        }

        function onMouseMove(e) {
          if (!isHovering || !imagesLoaded) return;

          const rect = gallery.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const width = rect.width;

          const imageIndex = Math.floor((x / width) * totalImages);
          showImage(imageIndex);
        }

        function loadImages() {
          images.forEach((img) => {
            if (!img.src && img.dataset.src) {
              img.src = img.dataset.src;
            }
          });
          imagesLoaded = true;
        }

        function showImage(index) {
          images.forEach((img, i) => {
            if (i === index) {
              img.classList.remove("hidden");
            } else {
              img.classList.add("hidden");
            }
          });
        }

        function hideAllImages() {
          images.forEach((img) => img.classList.add("hidden"));
        }

        article.addEventListener("mouseenter", onMouseEnter);
        article.addEventListener("mouseleave", onMouseLeave);
        article.addEventListener("mousemove", onMouseMove);

        galleries.push({
          destroy: () => {
            article.removeEventListener("mouseenter", onMouseEnter);
            article.removeEventListener("mouseleave", onMouseLeave);
            article.removeEventListener("mousemove", onMouseMove);
            hideAllImages();
          },
        });
      });
    }
  }

  // Initialize galleries on load
  initializeGalleries();

  // Reinitialize galleries on window resize
  let resizeTimer;
  window.addEventListener("resize", function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      initializeGalleries();
    }, 250);
  });

  // Reinitialize on event
  window.addEventListener("searchResultUpdated", function () {
    initializeGalleries();
  });
});
