// Ensure this script is loaded after axios and Alpine.js

(function () {
  function handleFavoriteClick(action, id) {
    return axios.post(`/api/favorites/${action}?id=${id}`).then(() => {
      const addButton = document.querySelector(
        `.add-favorite[data-id="${id}"]`
      );
      const removeButton = document.querySelector(
        `.remove-favorite[data-id="${id}"]`
      );
      if (addButton) addButton.classList.toggle("active");
      if (removeButton) removeButton.classList.toggle("active");
      const favoriteCar =
        addButton?.closest(".favorite-car") ||
        removeButton?.closest(".favorite-car");
      if (favoriteCar) {
        favoriteCar.classList.toggle("removed", action === "delete");
      }
    });
  }

  // Make functions available to Alpine.js
  window.alpineFunctions = {
    addFavorite(id) {
      return handleFavoriteClick("add", id);
    },
    removeFavorite(id) {
      return handleFavoriteClick("delete", id);
    },
    createLink() {
      return axios
        .post("/api/favorites/create-link?url=" + location.href)
        .then((response) => {
          const input = document.getElementById("share-link-input");
          if (input) {
            input.value = response.data.link;
            input.classList.toggle("hidden");
          }
          document.getElementById("create-link")?.classList.toggle("hidden");
          document.getElementById("copy-link")?.classList.toggle("hidden");
        });
    },
    copyLink() {
      const input = document.getElementById("share-link-input");
      if (input) {
        input.select();
        input.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(input.value);
      }
    },
  };

  // Initialize global search if it exists
  window.initializeGlobalSearch = function (storeId, locale) {
    if (window.Alpine && window.Alpine.store("globalSearch")) {
      window.Alpine.store("globalSearch").initialize(storeId, locale);
    }
  };

  // Add event listeners for non-Alpine.js elements
  document.addEventListener("DOMContentLoaded", () => {
    document.body.addEventListener("click", (event) => {
      const addFavorite = event.target.closest(".add-favorite");
      const removeFavorite = event.target.closest(".remove-favorite");
      if (addFavorite) {
        handleFavoriteClick("add", addFavorite.dataset.id);
      } else if (removeFavorite) {
        handleFavoriteClick("delete", removeFavorite.dataset.id);
      }
    });

    document
      .getElementById("create-link")
      ?.addEventListener("click", window.alpineFunctions.createLink);
    document
      .getElementById("copy-link")
      ?.addEventListener("click", window.alpineFunctions.copyLink);
  });
})();
