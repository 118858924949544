import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Fade effects
(function () {
  // Select all elements with animate attribute and animate them
  const animatedElements = gsap.utils.toArray("[data-animate]");

  animatedElements.forEach((el) => {
    const animation = el.getAttribute("data-animate");
    const delay = parseInt(el.getAttribute("data-delay")) || 0;

    if (animation) {
      gsap.from(el, {
        opacity: 0,
        ...getAnimationProperties(animation),
        duration: 0.3,
        delay: delay / 1000, // Convert delay to seconds
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          toggleActions: "play none none none",
        },
      });
    }
  });

  // Helper function to get animation properties based on the animation name
  function getAnimationProperties(animation) {
    const animations = {
      "fade-up": { y: 40 },
      "fade-down": { y: -40 },
      "fade-right": { x: -40 },
      "fade-left": { x: 40 },
      fade: {},
    };

    return animations[animation] || {};
  }
})();
