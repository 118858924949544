// import Swiper JS
import Swiper from "swiper";
// import Swiper styles
import "swiper/css";

document.addEventListener("DOMContentLoaded", function () {
  const swiper = new Swiper(".slider", {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 32,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: ".swiper-pagination",
    },
  });
});

document.addEventListener("DOMContentLoaded", function () {
  const swiper = new Swiper(".slider-cars", {
    slidesPerView: 1,
    spaceBetween: 4,

    navigation: {
      nextEl: ".slider-cars-next",
      prevEl: ".slider-cars-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },

      1024: {
        slidesPerView: 1.5,
      },
    },
    on: {
      slideChange: function () {
        var currentSlide = document.querySelector(".swiper-slide-active img");
        var mainImage1 = document.getElementById("main-image-1");
        var mainImage2 = document.getElementById("main-image-2");

        if (currentSlide && mainImage1 && mainImage2) {
          // Determine which image is currently visible
          var visibleImage = mainImage1.classList.contains("opacity-100")
            ? mainImage1
            : mainImage2;
          var hiddenImage = mainImage1.classList.contains("opacity-100")
            ? mainImage2
            : mainImage1;

          // Fade out the currently visible image
          visibleImage.classList.remove("opacity-100");
          visibleImage.classList.add("opacity-0");

          // Set the source of the hidden image and fade it in
          hiddenImage.src =
            currentSlide.src + "&quality=80&width=600&height=340&format=webp";
          setTimeout(function () {
            hiddenImage.classList.remove("opacity-0");
            hiddenImage.classList.add("opacity-100");
          }, 10); // Small delay to ensure the class is applied
        }
      },
    },
  });
  document.querySelector('.slider-cars-next')?.addEventListener('click', function() {
    swiper.slideNext();
  });
  
  document.querySelector('.slider-cars-prev')?.addEventListener('click', function() {
    swiper.slidePrev();
  });
});



document.addEventListener("DOMContentLoaded", function () {
  var cardSwiper = new Swiper(".cards-swiper", {
    slidesPerView: "3.5",
    spaceBetween: 4,
    grabCursor: true,
    breakpoints: {
      0: {
        slidesPerView: 1.7,
      },
      640: {
        slidesPerView: 2.5,
      },

      1024: {
        slidesPerView: 3.5,
      },
    },
  });

  const favoriteswiper = new Swiper(".favorite", {
    slidesPerView: 1,
    watchSlidesProgress: true,
    spaceBetween: 0,

    breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 1,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
      },
      // when window width is >= 768px
      768: {
        slidesPerView: 2,
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 3,
      },
      // when window width is >= 1024px
      1280: {
        slidesPerView: 4,
      },
      //  // when window width is >= 1024px
      //  1536: {
      //  	slidesPerView: 6,
      //  	spaceBetween: 32,
      //  },
    },

    scrollbar: {
      el: ".swiper-scrollbar",
      snapOnRelease: true,
      dragClass: "swiper-scrollbar-drag",
    },
  });

  const scrollingCarsSwiper = new Swiper(".scrollingCars-swiper", {
    grabCursor: true,
    slidesPerView: 6.5,
    spaceBetween: 12,

    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
    },

    breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 1.5,
      },

      // when window width is >= 768px
      768: {
        slidesPerView: 2.5,
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 3.5,
      },
      // when window width is >= 1024px
      1400: {
        slidesPerView: 4.5,
      },

      // when window width is >= 1024px
      1600: {
        slidesPerView: 5.5,
      },
    },
  });
});
